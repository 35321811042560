import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import * as getters from "./getters"
import * as actions from "./actions"
import * as mutations from "./mutations"

Vue.use(Vuex)

const state = {
  lafia_user: {
    is_logined: false,
    user_id: "",
    name: "",
    phone: "",
    email: "",
    profile_image: "",
    type: "",
    status: "",
    created: "",
  },

  lafia_admin: {
    is_logined: false,
    name: "",
    type: "",
    user_id: "",
  },

  lang: "",

  temp_sns_user: {},
  promotionCode: null,
  isShowinappBrowserWarning: false,

  home_selected_tab: "shotlist",

  default_price: null,
  default_package_price: null,

  concepts: [],
  isSupportAbroadPay: true,

  app_reload_time: 0,
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [
    createPersistedState({
      paths: ["lafia_user", "lafia_admin", "app_reload_time", "lang"],
    }),
  ],
})
