import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
// import colors from 'vuetify/lib/util/colors'
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#8d75cb",
        secondary: "#e9e5ff",
        accent: "#66546d",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#d8207e",
      },
    },

    breakpoint: {
      mobileBreakpoint: 600,
    },
  },
})
